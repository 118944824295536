import(/* webpackMode: "eager" */ "/Users/osmall/code-git/beaconhost-website/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/Users/osmall/code-git/beaconhost-website/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/osmall/code-git/beaconhost-website/components/avatarDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuItem"] */ "/Users/osmall/code-git/beaconhost-website/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetTrigger","SheetContent"] */ "/Users/osmall/code-git/beaconhost-website/components/ui/sheet.tsx");
;
import(/* webpackMode: "eager" */ "/Users/osmall/code-git/beaconhost-website/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/Users/osmall/code-git/beaconhost-website/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
